import { Alert } from '@mui/material';

interface Props {
  fournisseurConfirmed: boolean
  status: string
  isProcedureStarted: boolean
  clientConfirmation: boolean
}

function BonSignatureAlertStatus({ status, fournisseurConfirmed, isProcedureStarted, clientConfirmation }: Props) {

  //const hasBeenProcessed = () => !!status && !['DRAFT', 'CREATED'].includes(status);
//ou a été refusé
  if ((fournisseurConfirmed && !clientConfirmation) || (!fournisseurConfirmed && clientConfirmation))
    return (<Alert color="info" variant="filled">
      Ce bon est en attente d'un signataire
    </Alert>);
  
  if (fournisseurConfirmed && clientConfirmation)
    return (<Alert color="warning" variant="filled">
      Vous avez déjà signé ce bon
    </Alert>);

  if (isProcedureStarted)
    return (<Alert color="warning" variant="filled">
      Ce bon est en attente d'être signé
    </Alert>);



  return null;
}


export default BonSignatureAlertStatus;