import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EntityId } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { BonEntity } from '../../../core/bon/entities/bon.entity';
import { RootState } from '../../../core/store';
import { CustomTable } from '../../common/custom-table';
import { ColumnEntity } from '../../common/custom-table/Table.entities';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import BonTableActionsCell from './BonTableActionsCell';
import { Status, BonTableStatusCell } from './BonTableStatusCell';
import { retreiveListOfSignableBons } from '../../../core/bon/use-cases/retreive-list-of-signable-bons';
import { RowDataType } from 'rsuite-table';
import { selectAll } from '../../../core/bon/selectors';
import { formatRangeDatesStringToFromToObject } from '../../common/_helpers';

// import { retrieveBons } from '../../../../core/bon/use-cases';
// import { selectAllBons } from '../../../../core/bon/selectors';

// import BonTableActionsCell from './BonTableActionsCell';
// import { RootState } from '../../../../core';
// import { CustomTable } from '../../../common/custom-table';
// import { BonEntity } from '../../../../core/bon/entities';
// import { ColumnEntity } from '../../../common/custom-table/Table.entities';
// import { validateDates } from '../../../common/_helpers';
// import { BonTableStatusCell, Status } from './BonTableStatusCell';

interface Props {
  onSelectRows(ids: EntityId[]): void;
}

function BonTable({ onSelectRows }: Props) {
  const [isLoading, setIsLoading] = useState(true);

  const bons = useSelector(selectAll);
  // const bons: RowDataType[] = []
  const selectedDates = useSelector((state: RootState) => state.bon.dates);
  const filters: any = useSelector((state: RootState) => state.bon.filters);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    let validDates = formatRangeDatesStringToFromToObject(selectedDates);

    // request will be sent if both dates are valid or no dates is selected
    if (!!validDates) {
      const { fromDate, toDate } = validDates;
      dispatch(retreiveListOfSignableBons({ filters, fromDate, toDate }))
        .then(() => setIsLoading(false));
    }

    return () => {
      setIsLoading(true);
    };
  }, [dispatch, selectedDates, filters]);

  const onRowDoubleClick = ({ id }: BonEntity) => navigate(`../planning/c/${id}`, { state: id });

  let tableBons = JSON.parse(JSON.stringify(bons));;
  tableBons.forEach((bon:any) => {
    
    if(bon.procedure.fournisseurConfirmation && bon.procedure.clientConfirmation) { bon.status = 'SIGNED'; }
    if(!bon.procedure.fournisseurConfirmation && bon.procedure.clientConfirmation) { bon.status = 'PENDING'; }
    if(bon.procedure.fournisseurConfirmation && !bon.procedure.clientConfirmation) { bon.status = 'PENDING'; }
    if(!bon.procedure.fournisseurConfirmation && !bon.procedure.clientConfirmation) { bon.status = 'CREATED'; }
  });
//{ field: 'referenceClient', headerName: 'Ref. Client', width: 130, sortable: true, resizable: true },
  const columns: ColumnEntity[] = [
    {
      field: 'status',
      headerName: '',
      width: 40,
      renderCell: (param) =>
        Object.values(Status).includes(param as Status)
          ? <BonTableStatusCell param={param} />
          : param,
    },
    { field: 'date', headerName: 'Date', width: 110, sortable: true, resizable: true },
    
    { field: 'bonNumber', headerName: 'N° Bon', width: 125, sortable: true, resizable: true },
    { field: 'client', headerName: 'Client', width: 140, sortable: true, resizable: true },
    { field: 'chantier', headerName: 'Chantier', width: 140, sortable: true, resizable: true },
    { field: 'commandePar', headerName: 'Commandé par', width: 160, sortable: true, resizable: true },
    { field: 'article', headerName: 'Article', width: 140, sortable: true, resizable: true },
    { field: 'fournisseur', headerName: 'Fournisseur', width: 140, sortable: true, resizable: true },
    { field: 'unite', headerName: 'Unité', width: 80, sortable: true, resizable: true },
    { field: 'quantite', headerName: 'Qte', width: 60, sortable: true, resizable: true },
    { field: 'prixVenteHT', headerName: 'PV HT', sortable: true, resizable: true },
    { field: 'montantHT', headerName: 'Montant HT', width: 110, sortable: true, resizable: true },
  ];

  return (
    <Box sx={{ height: '100%' }}>
      <CustomTable
        data={tableBons}
        sortByDefault="date"
        columns={columns}
        loading={isLoading}
        onRowDoubleClick={onRowDoubleClick}
        onSelectRows={onSelectRows}
      // renderActionCell={(rowData, measuredRef) => <BonTableActionsCell id={rowData.id} measuredRef={measuredRef} />}
      />
    </Box>
  );
}

export default BonTable;
