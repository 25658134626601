import * as React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation, Location, /*useSearchParams,*/ Outlet } from 'react-router-dom';

// import ProtectedRoute from './ProtectedRoute';
// import PublicRoute from './PublicRoute';

import { LoginView, SendMailToResetPassword, ResetPasswordConfirm } from './login';
import { selectIsLoggedIn, selectRole } from '../core/auth/selectors';
import ProtectedRoute from './ProtectedRoute';
import PlanningList from './planning/components/PlanningList';
import { PlanningValidationView } from './planning/components/PlanningValidationView';
import { ClientValidationView } from './planning/components/ClientValidationView';
import ChauffeurRoute from './ChauffeurRoute';
import FournisseurRoute from './ChauffeurRoute';
import { UserList } from './chauffeurs/components/user-list';
import ClientProtectedRoute from './ClientProtectedRoute';
import { UserAccountView } from './user-account/components';
import BonList from './bon/bon-list/BonList';
import { UserEdition } from './chauffeurs/components/user-edition';
// import { PlanningRouter } from '../planning/routes';
// import { UserRouter } from '../user/routes';
// import EntrepriseRouter from '../entreprise/routes';
// import ChantierRouter from '../chantier/routes';
// import { BonRouter } from '../bon/routes';
// import ChantierModal from '../chantier/components/ChantierModal';
// import EntrepriseModal from '../entreprise/components/EntrepriseModal';
// import ArticleModal from '../article/components/ArticleModal';
// import InterlocuteurModal from '../interlocuteur/components/InterlocuteurModal';

function AppRoute() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const currentRole = useSelector(selectRole);
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };
  // const [searchParams] = useSearchParams();
  // const modal = searchParams.get('modal');
  const from = (location.state as any)?.from?.pathname || '/';

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <Route element={isLoggedIn ? <Navigate replace to={from} /> : <Outlet />}>
          <Route path="/login" element={<LoginView />} />
          <Route path="/reset-password" element={<SendMailToResetPassword />} />
          <Route path="/reset-password-confirm" element={<ResetPasswordConfirm />} />
        </Route>
        <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
          <Route path="/planning" element={<PlanningList />} />
          <Route path="/planning/:id" element={<PlanningValidationView />} />
          <Route path="/planning/c/:id" element={<ClientValidationView />} />

          <Route path="/mon-compte" element={<UserAccountView />} />

          <Route element={<ClientProtectedRoute role={currentRole} />}>
            <Route path="/bons" element={<BonList />} />
            <Route path="/" element={<Navigate replace to="/bons" />}></Route>
          </Route>


          <Route element={<FournisseurRoute role={currentRole} />}>
            {/* <Route path="/bons" element={<BonList />} /> */}
            <Route path="/user" element={<UserList />} />
            <Route path="/user/:id" element={<UserEdition />} />
            <Route path="/user/create" element={<UserEdition />} />
          </Route>


          <Route path="/mon-compte" element={<UserAccountView />} />
        </Route>
        <Route path="/" element={<Navigate replace to="/planning" />}></Route> {/* Default Route */}
        <Route path="*" element={<Navigate replace to="/login" />}></Route> {/* No Match Route */}
      </Routes>

      {/* Modal Routes */}
      {/* {state?.backgroundLocation && modal && (
        <Routes>
          <Route path="planning/create" element={getComponentByName(modal)} />
          <Route path="planning/:id" element={getComponentByName(modal)} />
          <Route path="user/create" element={getComponentByName(modal)} />
          <Route path="bon/create" element={getComponentByName(modal)} />
        </Routes>
      )} */}
    </>
  );
}

// function getComponentByName(name: string) {
//   // in production comp names are different (minimified), using ifs until a better approach is found
//   //   const comps = [ChantierModal, EntrepriseModal, ArticleModal, InterlocuteurModal];
//   //   const Component = comps.find((component) => component.name.toLowerCase().includes(name));
//   //   if (Component) return <Component />;
//   if (name === 'article') return <ArticleModal />;
//   if (name === 'chantier') return <ChantierModal />;
//   if (name === 'interlocuteur') return <InterlocuteurModal />;
//   if (['client', 'fournisseur', 'employeur'].includes(name)) return <EntrepriseModal />;
//   return <></>;
// }

export default AppRoute;
