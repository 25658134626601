import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isValid, parse } from 'date-fns';

import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { retrieveAllPlanning } from '../../../core/planning/use-cases';
import { selectPlanningBySearch } from '../../../core/planning/selectors';
import { RootState } from '../../../core/store';

import { CircularProgress, Divider, IconButton, List, ListItem, ListItemText, Typography, Box } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';

function PlanningTable() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const planning = useSelector(selectPlanningBySearch);
  const selectedDate = useSelector((state: RootState) => state.planning.date);

  useEffect(() => {
    let date = parse(selectedDate.trim(), 'dd/MM/yyyy', new Date());

    if (isValid(date)) {
      dispatch(retrieveAllPlanning()).then(() => {
        setIsLoading(false);
      });
    }
    return () => {
      setIsLoading(true);
    };
  }, [dispatch, selectedDate]);

  const onRowClick = (id: string) => navigate(`./${id}`, { state: id });

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 6 }}>
        <CircularProgress />
      </Box>
    );
  }
//console.log(planning);
  return (
    <>
      {!!planning.length ? (
        <List>
          {planning.map((line) => (
            <Box key={line.id}>
              <ListItem
                key={line.id}
                alignItems="flex-start"
                onClick={() => onRowClick(line.id)}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete">
                    <NavigateNext />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={`${line.client} - ${line.article}`}
                  secondary={
                    <>
                      <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                        {line.date} - {line.chantier ?? ''} - {line.adresseChantier ?? ''} 
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </Box>
          ))}
        </List>
      ) : (
        <Typography variant="body1" m={2}>
          Aucune donnée...
        </Typography>
      )}
    </>
  );
}

export default PlanningTable;
